



























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import API from '@/modules/API';
import { User, Global } from '@/store';
import MessageDialog from '@/components/dialogs/MessageDialog.vue';
import moment from 'moment';
import Utils from '@/modules/Utils';

@Component({
  components: { MessageDialog }
})

export default class RecreateData extends Vue {
    @Prop() last_time_update;
    @User.State('project') project;
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @Global.State('timezone') projectTimezone;

    isMessageDialogOpen = false;
    message = 'Data will be updated in a few minutes';
    type = 'success';

    mounted() {
        this.getlastUpdateColor();
    }
    
    @Watch('last_time_update')
    getlastUpdateColor(){
        const today_timestamp = moment().unix() * 1000;
        const server_update = this.last_time_update 
            ? moment(this.last_time_update, 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DD')
            : '';
        const today_timezone = Utils.convertTimestamp(today_timestamp, 'YYYY-MM-DD', this.projectTimezone);
        const before_three_days = Utils.removeFromTimestamp_string(today_timestamp, 'days', 3, 'YYYY-MM-DD', this.projectTimezone);
        const before_week = Utils.removeFromTimestamp_string(today_timestamp, 'weeks', 1, 'YYYY-MM-DD', this.projectTimezone);

        this.type = server_update && Utils.isDateBetween(server_update, before_three_days, today_timezone, '[]') 
            ? 'success'
            : server_update && Utils.isDateBetween(server_update, before_week, before_three_days, '[)')
            ? 'warning'
            : 'error';
    }

    async handleRecreateClicked(){
        this.isMessageDialogOpen = true;
        try{
            await this.updateFixtures();
            await this.updateCabinets();
            await this.updateSai();
        }catch (e){}
    }

    async updateFixtures(){
        const url = 'communication_monitoring_update';
        const body = {
            project_id: this.project.id,
            company_id: this.project.company
        };
        const response = await API.dashboardAPI(url, 'POST', {}, body);

    }

    async updateCabinets(){
        const url = 'communication_monitoring/cabinets';
        const body = {
            project_id: this.project.id,
            company_id: this.project.company
        };
        const response = await API.dashboardAPI(url, 'POST', {}, body);
    }

    async updateSai() {
        const url = 'update/monogoto_status_stream';
        const body = {
            project_id: this.project.id,
            company_id: this.project.company
        };
        const response = await API.dashboardAPI(url, 'POST', {}, body);
    }
}

